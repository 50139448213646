import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Link } from '../../models/link';
import { EpayService } from 'src/app/services/epay.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuarios } from 'src/app/models/usuarios.model';
import { Establecimientos } from 'src/app/models/Establecimientos';
import { PerfilServicios } from 'src/app/models/perfil-servicios';



@Component({
  selector: 'app-producto-tienda',
  templateUrl: './producto-tienda.component.html',
  styleUrls: ['./producto-tienda.component.scss'],
})
export class ProductoTiendaComponent implements OnInit {

  linkObj = new Link("","","",0,1,"", "","", "", "", "", 0,false, false,false, 0,0,0,false,0,true);
  moneda = "1";
  cuotasSeleccionadas = ["0-00"];

  
  linkIdCifrado = "12234dsdasdsad";
  baseUrl = "https://swigit.gt/link/";
  baseUrlFicha = "https://swigit.gt/link-producto/";

  //integracion FEL
  establecimiento: string;
  listaEstablecimientos: Establecimientos[];
  facturacionAutomatica: boolean = false;
  // recurrencia: boolean = false;
  cobroCuotas: boolean = false;
  felActivo: boolean = false;
  bienoServicio: string = "S";
  planEpay: number;
  //integracion FEL

  //cybersource
  cyberMerchantID: string = null;

  //ficha de producto
  esFichaProducto: boolean = false;
  formData: FormData = new FormData();


    constructor(private epayService: EpayService,
                private alertController: AlertController,
                private toastCtrl: ToastController,
                private router: Router,
                private loading: LoadingService,
                private usuarioService: UsuarioService,
                public modalCtrl: ModalController) { }
  
    ngOnInit() {
    }

    async presentAlertConfirm() {
   
      const alert = await this.alertController.create({
        header: 'Link creado',
        message: ' <p style="font-style: normal; font-weight: bold; font-size: 17.904px; line-height: 24px;color: #4A4D4E">¡Listo! Puedes copiar o ver tu link de pago </p>',
        buttons: [
          {
            text: 'Copiar',
            cssClass: 'secondary',
            handler: (blah) => {
              
              //this.baseUrl + this.linkIdCifrado
              this.presentToast('¡Excelente! Puedes compartir el link a tu cliente. ');

            }
          }, {
            text: 'Ver ',
            handler: () => {
              
                if(this.linkObj.esFichaProducto == false)
                  this.router.navigate(['/link/' + this.linkIdCifrado]);
                else
                  this.router.navigate(['/link-producto/' + this.linkIdCifrado]);
            }
          }
        ]
      });
  
      await alert.present();
     
    }

    ionViewDidEnter()
    {
     
      this.setEstablecimientos();
      this.getServicios();
    }

    salirModal()
    {
      this.modalCtrl.dismiss();
    }

    handleFileInput(files: FileList) {
       console.log(files.item(0));
      this.formData = new FormData();
      this.formData.append('fileKey', files.item(0), files.item(0).name);
  
    }

    mandarImagen(Id)
    {
      // console.log(pagoId);
      var request = new XMLHttpRequest();
      request.open("POST", "https://fileapi.swigit.gt/Upload/"+ Id);
      request.onload =  () => {
        if (request.readyState === 4) {
          if (request.status === 200) {
            this.loading.dismiss();
            this.presentToast("Producto creado");
            this.salirModal();
       
          } else {
            this.loading.dismiss();
          }
        }
      };
      request.onerror = () =>  {
        this.loading.dismiss();
      };
      request.send(this.formData);
    }

    getServicios()
    {
  
      let perfilServicioObj: PerfilServicios = JSON.parse(localStorage.getItem("perfilServicios"));
      console.log("LLEGO SERVICIO LINK");
      console.log(perfilServicioObj);
      this.felActivo = perfilServicioObj.felActivo;
      this.planEpay = perfilServicioObj.planEpay;
      this.cyberMerchantID = perfilServicioObj.cyberMerchantId;
    }
  
  
    registrarLink(){
 
      this.loading.present();
      var cuotasList = "";
      //se arma el objeto los campos externos
      this.linkObj.moneda = parseInt(this.moneda);
      this.cuotasSeleccionadas.forEach(function (value) {

        if(cuotasList != "") 
          cuotasList = cuotasList + "," + value;
        else
          cuotasList = value;

      }); 
      this.linkObj.cuotas = cuotasList;
      if(this.facturacionAutomatica == true)
      {
      this.linkObj.establecimiento = this.establecimiento.split("-")[1];
      this.linkObj.codigoEstablecimiento = parseInt(this.establecimiento.split("-")[0]);
      this.linkObj.facturaAutomatica = this.facturacionAutomatica;
      this.linkObj.esServicio = this.bienoServicio == "S" ? true : false;
      }

      console.log(this.linkObj);
       //fin pruebas
       this.epayService.postLink(this.linkObj).subscribe(data =>
       {
        console.log("DATA LINK");
        console.log(data);
        if(this.linkObj.esFichaProducto == true && this.formData.has("fileKey"))
        {
          this.mandarImagen(data.lp);
        }
        else
        {
          this.loading.dismiss();
          this.presentToast("Producto creado");
          this.salirModal();
        }
          

          /*
        this.linkIdCifrado = data.linkPagoToken;

        if(this.linkObj.esFichaProducto == false)
          this.copyMessage(this.baseUrl + this.linkIdCifrado);
        else
          this.copyMessage(this.baseUrlFicha + this.linkIdCifrado);

         this.loading.dismiss();
         this.presentAlertConfirm();
         */

       },
       error => {
         console.log(error);
         this.loading.dismiss();
         this.presentToast('¡Lo sentimos! Has llegado al límite de productos que puedes cargar en la versión gratuita. Comunícate con nosotros para adquirir un plan premium y continuar creando productos. ');
       });


    }
    async presentToast(mensaje: string) {
      const toast = await this.toastCtrl.create({
        message: mensaje,
        duration: 6000
      });
      toast.present();
    }

    copyMessage(val: string){
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

    limpiarForm()
    {
      this.linkObj = new Link("","","",0,1,"", "","", "", "", "",0,false, false);
    }

    setEstablecimientos()
    {
      this.usuarioService.getUsuarioId(localStorage.getItem("personaId")).subscribe(data =>
      {
        console.log(data);
        this.listaEstablecimientos = data.listaEstablecimientos;
        this.establecimiento = this.listaEstablecimientos[0].codigoEstablecimiento + "-" + this.listaEstablecimientos[0].nombreComercial;
      });
    }



}
