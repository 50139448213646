import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { CuentaBancaria } from 'src/app/models/cuenta-bancaria';
import { LoadingService } from 'src/app/services/loading.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-cuenta-bancaria',
  templateUrl: './cuenta-bancaria.component.html',
  styleUrls: ['./cuenta-bancaria.component.scss'],
})
export class CuentaBancariaComponent implements OnInit {

  cuentaBancaria = new CuentaBancaria("","", "","");
  usuario: any;
  personaId: string;

  constructor( private toastCtrl: ToastController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loading: LoadingService,
    private usuarioService: UsuarioService,
    public modalCtrl: ModalController) { }

  ngOnInit() {

  }

  ionViewDidEnter()
  {

    this.personaId = localStorage.getItem("personaId");

    this.loading.present();
    this.usuario = this.usuarioService.getUsuarioToken(localStorage.getItem("token")).subscribe(data =>
      {
        this.usuario = data;
        if(this.usuario.persona.cuentaBancaria == null)
        {
     
        }
        else
        {
          this.cuentaBancaria = JSON.parse(this.usuario.persona.cuentaBancaria);
          console.log("LLEGO CUENTA BANCARIA");
          console.log(this.cuentaBancaria);

        }
        console.log("DATA USUARIO");
        console.log(this.usuario);
        this.loading.dismiss();

      },
      error => {
        this.loading.dismiss();
        console.log(error);
        this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
      });

  }

  salirModal()
  {
    this.modalCtrl.dismiss();
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 2000
    });
    toast.present();
  }

  editarCuentaBancaria()
  {

    this.usuario.listaFrases = null;
    this.usuario.listaEstablecimientos = null;
    this.usuario.persona.cuentaBancaria =  JSON.stringify(this.cuentaBancaria);
    console.log(this.usuario);

   this.loading.present();
    this.usuarioService.putUsuarioReturn(this.usuario).subscribe(data =>
      {

        this.loading.dismiss();
        this.presentToast("¡Tu cuenta bancaria ha sido agregada!");
        this.salirModal();

      },
      error => {
        this.loading.dismiss();
        console.log(error);
        this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
      });

  }

}
