import { DatosGenerales } from './datos-generales';
import { Frase } from './frase';
import { Receptor } from './receptor';
import { Emisor } from './emisor';
import { Item } from './item';
import { Totales } from './totales';
import { TotalImpuestos } from './total-impuestos';

export class DatosEmision {
    public ID: string;
    public DatosGenerales: DatosGenerales;
    public Frases = [];
    public Items = [];
    public Emisor: Emisor;
    public Receptor: Receptor;
    public Totales: Totales;

    constructor(nit:string, nombre:string, correo:string,
        direccion: string, nit_emisor: string, 
        nombreEmisor:string, codigoEstablecimiento: string, nombreComercial: string, correoEmisor: string, afiliacionIVA: string){
        this.ID = "DatosCertificados";
        this.DatosGenerales = new DatosGenerales();
        this.Frases.push(new Frase("1","2"));
        this.Emisor = new Emisor(nit_emisor, nombreEmisor, codigoEstablecimiento, nombreComercial, correoEmisor, afiliacionIVA);
        this.Receptor = new Receptor(nit,nombre,correo,direccion);
    }

    agregarProductos(Items: any){
        this.Items = Items;
        console.log("items nuevos");
        console.log(this.Items);
        let numerolinea = 1;
        let total = 0;
        let totalImpuestos = 0;
        let totalAux = 0;
        let totalImpuestos2 = 0;
        let impAux = 0;
        this.Items.forEach(function (item, index) {
            totalAux = 0;
            //console.log(item, index);
            item.NumeroLinea = numerolinea;
            total = parseFloat(( total + item.Total ).toFixed(6)) ;
            totalImpuestos = parseFloat(( totalImpuestos + item.Impuestos[0].MontoImpuesto.Value ).toFixed(6)) ;
            //totalImpuestos = parseFloat(( totalImpuestos + item.Impuestos[0].MontoImpuesto.Value.toFixed(6) )) ;
            numerolinea++;
            totalAux = parseFloat(item.Total);
            console.log("totalAux: " + totalAux);
            impAux = parseFloat((0.12 * totalAux / 1.12).toFixed(6));
            console.log("impuesto linea : " + impAux);
            totalImpuestos2 = parseFloat((totalImpuestos2 + impAux).toFixed(6));
            console.log("propuesta impuestos : " + totalImpuestos2);
        });
        console.log("totalFnal: " + totalImpuestos2);
        this.Totales = new Totales(total, "IVA", totalImpuestos2);
    }
}
