export class Frase {
    public TipoFrase: string;
    public CodigoEscenario: string;

    constructor(tipofrase: string, codigoescenario: string){
        this.TipoFrase = tipofrase;
        this.CodigoEscenario = codigoescenario;
    }

}
