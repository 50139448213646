import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PagosUsuarioService } from '../../services/pagos-usuario.service';
import {Observable} from 'rxjs';
import { IonCheckbox, ModalController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Link } from '../../models/link';
import { Epay } from 'src/app/models/epay';
import { EpayService } from 'src/app/services/epay.service';
import { FELObj } from 'src/app/models/fel/felobj';
import { Item } from 'src/app/models/fel/item';
import { FelService } from 'src/app/services/fel.service';
import { PerfilServicios } from 'src/app/models/perfil-servicios';
import { resultado_certificacion } from 'src/app/models/fel/resultado_certificacion';
import { Transaccion } from 'src/app/models/transaccion';
import { EpayResponse } from 'src/app/models/epay-response';
import { PerfilPago } from 'src/app/models/perfil-pago';
import { NombreNIT } from 'src/app/models/nombre-nit';
import { AuthorizationRequestWithClientToken } from 'src/app/models/authorization-request-with-client-token';

@Component({
  selector: 'app-modal-pago',
  templateUrl: './modal-pago.component.html',
  styleUrls: ['./modal-pago.component.scss'],
})
export class ModalPagoComponent implements OnInit {

  pago = {
    visa: true,
    mastercard: false,
    nombres: '',
    apellidos: '',
    numeroTarjeta: '',
    cvv: '',
    fechaVencimiento: '',
    paquete: '',
    correo: '',
    cuotas: ''
  
    };



  listadoPaquetes:Observable<any>;
  premio:string = '';

  @ViewChild('checkvisa', null) checkboxVisa: IonCheckbox;
  @ViewChild('checkmaster', null) checkboxMaster: IonCheckbox;
  fechaCard: Date = new Date();
  customPickerOptions;
  arregloNumeroTarjeta;
  numeroTarjetaS;
  respuesta; 
  personaId: number;
  email: string;
  puntos: number;
  token: string;
  epayObj = new Epay("4000000000000416", 
  "2401", 0, "", "123", "127.0.0.1", "",
   "Jaime Samuel Garcia Soloman", "info@digitalrising.com.gt",1, 2);
  requestTokenObj = new AuthorizationRequestWithClientToken("",1,0,"192.168.1.1");


  //cantidad a pagar por paquetes
  emprende: 50;
  micro: 200;
  pymes: 400;
  pymesPlus: 600;
  premium: 900;
  linkIdCifrado = "";
  usuario: any;
  srcimage:string = "https://digitalrising.com.gt/wp-content/uploads/2020/10/sinperfil.png";
  linkObj = new Link("","", "", 
  0, 1, "", "", "", "", "","", 0, false, false, true, 5, 12, 0, false);
  monedaString: string;
  cuotas = [];

  cuotaSeleccionada: string = "0-00";
  pintarCuotas: boolean;
  perfilpagoObj = new PerfilPago(0,"", "", "", "", "", "", "", "", "", 0,0, "", "");
  colorEncabezado: string = "linear-gradient(270deg, #5AC8FA 0%, #01519D 100%), #01519D";
  colorLetras: string = "#fff";

  //integracion modulo FEL 
  nit: string;
  nitnombre: string;
  direccion: string = "Ciudad";
  descripcion: string;
  bienoServicio: string = "B";
  facturacionAutomatica: boolean = false;
  felActivo: boolean = false;
  felenLink: boolean = false;
  planEpay: number;

  nitObj = new NombreNIT("");
  @ViewChild('chkTokenizar', null) chkTokenizar: IonCheckbox;
  mostrarCorreo: boolean = false;
  //integracion modulo FEL 


  constructor(private pagoService: PagosUsuarioService,
              private epayService: EpayService,
              private router: Router,
              private toastCtrl: ToastController,
              private loading: LoadingService,
              private activatedRoute: ActivatedRoute,
              private usuarioService: UsuarioService,
              private felService: FelService,
              private ref: ChangeDetectorRef,
              public modal: ModalController) {         

                    }

  ngOnInit() {

  
    this.loading.present();
    this.customPickerOptions = {
      buttons: [{
        text: 'Seleccionar',
        handler: ( evento ) => this.pago.fechaVencimiento = evento.month.value <= 9 ? "0"  + evento.month.value + "/" + evento.year.value : evento.month.value + "/" + evento.year.value
      }, {
        text: 'Cancelar',
        handler: () => {
          //console.log('Clicked Log. Do not Dismiss.');
          
        }
      }]
    };


    this.epayService.getIPAddress().subscribe((res:any)=>{
      this.epayObj.shopperIP = res.ip.toString();
    });

  
  //  this.linkIdCifrado = this.activatedRoute.snapshot.paramMap.get("linkCifrado");
    this.epayService.getLink(this.linkIdCifrado).subscribe(data =>
     {
      
       this.linkObj = data;
       console.log(this.linkObj);
       this.srcimage = "https://files.swigit.gt/" + this.linkObj.nit + "/logo.jpg";  
       console.log(this.srcimage);
       this.monedaString = this.linkObj.moneda == 1 ? "Q." : "$.";
       this.cuotas = this.linkObj.cuotas.split(",");
       if(this.cuotas.length == 1 && this.cuotas[0].split("-")[0] == "0")
        this.pintarCuotas = false;
       else
        this.pintarCuotas = true;


   
     },
      error => {
        console.log("ERROR AL OBTENER LINK");
        console.log(error);
        this.loading.dismiss();
        this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
      });
    
  }

  ionViewWillEnter(){

  
    //this.linkIdCifrado = this.activatedRoute.snapshot.paramMap.get("linkCifrado");
    this.epayService.getLink(this.linkIdCifrado).subscribe(data =>
     {
      
       this.linkObj = data;
       console.log(this.linkObj);
       this.srcimage = "https://files.swigit.gt/" + this.linkObj.nit + "/logo.jpg";  
       console.log(this.srcimage);
       this.monedaString = this.linkObj.moneda == 1 ? "Q." : "$.";
       this.cuotas = this.linkObj.cuotas.split(",");
       if(this.cuotas.length == 1 && this.cuotas[0].split("-")[0] == "0")
        this.pintarCuotas = false;
       else
        this.pintarCuotas = true;

      //PRUEBA CYBERSOURCE
      // this.linkObj.recurrente = true;
      // this.linkObj.diaDeCobro = 5;
      // this.linkObj.cantidadMeses = 12;
      console.log("LINK CYBER: " + this.linkObj);
      console.log(this.linkObj);

      //asignar correo si trae valor (validacion celovendo)
      console.log("RESULTADO SPLIT");
      console.log(this.linkObj.nombre.split("*")[1]);

      if((this.linkObj.nombre.split("*")[1] != null) && (this.linkObj.token == "7d61fe80096fe9a74b9a240689f002ff" || this.linkObj.token == "defa5748a6a4a2b030be556d671da87b"))
      {
        console.log("llego if correo");
        //hay un correo para celovendo o swigitPruebas
        this.pago.correo =  this.linkObj.nombre.split("*")[1].trim();
        console.log(this.epayObj);
        this.mostrarCorreo = false;
      }
      else
      {
        this.mostrarCorreo = true;
      }


      this.getServicios();
      this.getPerfilpago();
      this.loading.dismiss();
 
     },
      error => {
        //console.log(error);
        document.documentElement.style.setProperty('--colorEncabezado',this.colorEncabezado.toString());
        document.documentElement.style.setProperty('--colorLetras',this.colorLetras.toString());
        this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
      });
 
    // this.srcimage = "https://files.swigit.gt/" + this.linkObj.nit + "/logo.jpg";  
    // this.monedaString = this.linkObj.moneda == 1 ? "Q." : "$.";
    // this.cuotas = this.linkObj.cuotas.split(",");

  }


  buscar(event)
  {
   
    let nit = event.detail.value;
    this.nitObj.nitConsulta = nit.replace("-", "").toUpperCase();
    console.log(this.nitObj);

    if(this.nitObj.nitConsulta.length >= 4)
    {

    this.felService.getNombreNIT(this.nitObj).subscribe(data =>
       {
        console.log("respuesta infile: " + JSON.stringify(data));
        this.nit= this.nitObj.nitConsulta;
        this.nitnombre = data.nombre;
       });

    }
    else if(this.nitObj.nitConsulta.toUpperCase() == "CF")
    {
      console.log("llego cf");
      this.nit = this.nitObj.nitConsulta;
      this.nitnombre = "Consumidor Final";
    }

    this.ref.detectChanges();
    
  }

  getServicios()
  {

    this.usuarioService.getPerfilServiciosPublic(this.linkObj.token).subscribe(data =>
      {
        console.log(data);
        let perfilServicioObj: PerfilServicios = data;
        this.felActivo = perfilServicioObj.felActivo;
        this.planEpay = perfilServicioObj.planEpay;

      },
      error =>
      {
        this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
      }
    );
  }

  getPerfilpago()
  {
    console.log("llego perfil pago");
    this.usuarioService.getPerfilPagoToken(this.linkObj.token).subscribe(data =>
    {

      this.perfilpagoObj = data;
      console.log(this.perfilpagoObj);
      
      if(this.perfilpagoObj.colorFondoEncabezadoVoucher != null)
      {
        this.colorEncabezado = this.perfilpagoObj.colorFondoEncabezadoVoucher;
        
      }

      if(this.perfilpagoObj.colorFuenteEncabezadoVoucher != null)
      {
        this.colorLetras = this.perfilpagoObj.colorFuenteEncabezadoVoucher;
      }

      document.documentElement.style.setProperty('--colorEncabezado',this.colorEncabezado.toString());
      document.documentElement.style.setProperty('--colorLetras',this.colorLetras.toString());

    },
    error => {
      console.log(error);
    });
  }


  pagar()
  {

    this.loading.present();
    //IMPORTANTE SETEAR EL TOKEN DEL CREADOR DEL LINK PARA QUE PUEDAN HACER TRANSACCIONES
    this.token = localStorage.getItem("token");
    this.personaId = parseInt(localStorage.getItem("personaId"));
    this.arregloNumeroTarjeta = this.pago.numeroTarjeta.split("-");
    this.numeroTarjetaS = this.arregloNumeroTarjeta[0] + this.arregloNumeroTarjeta[1] + this.arregloNumeroTarjeta[2] +this.arregloNumeroTarjeta[3];

    //Se modifica el objeto de Epay con las variables del pago
    this.epayObj.amount = this.linkObj.precio; 
    this.epayObj.pan = this.numeroTarjetaS;
    this.epayObj.expirationDate =  this.pago.fechaVencimiento.split("/")[1].substring(2) + this.pago.fechaVencimiento.split("/")[0];
    this.epayObj.cvV2 = this.pago.cvv;
    if(this.linkObj.esCyber == false)
    {
      this.epayObj.name = this.pago.nombres + ' ' + this.pago.apellidos;
    }
    else
    {
      
      this.epayObj.name = this.pago.nombres + ' ' + '|' + this.pago.apellidos;
      console.log("nombre con pipe: " + this.epayObj.name);
    }
    this.epayObj.moneda = this.linkObj.moneda;
    this.epayObj.email = this.pago.correo;
    if(this.cuotaSeleccionada.split("-")[1] != "00") //desea cuotas
      this.epayObj.additionalData = "VC" + this.cuotaSeleccionada.split("-")[1];
    

    // console.log(this.epayObj);
    this.epayObj.descripcionTransaccion = this.linkObj.nombre;

    if(this.linkObj.recurrente == false)
    {

      //puede ser pago con epay o con cybersource normal
      if(this.linkObj.esCyber == false)
      {
        console.log("ENTRO A COBRAR CON EPAY");
        this.epayService.requestEpayLink(this.epayObj, this.linkObj.token).subscribe(data =>
          {
          
                if(data.responseCode === "00")
                {

                  if(this.linkObj.facturaAutomatica == true)
                  {
                    this.facturar(data);    
                  }   
                  else
                  {
                    this.loading.dismiss();
                    this.cerrarModal();
                    localStorage.setItem("resultado_certificacion_epay", null);
                    localStorage.setItem("epayResponse", JSON.stringify(data) );
                    this.router.navigate(['/pago-exito-public' ]);
                  }
                
              
                }
                else
                {
                  this.loading.dismiss();
                  //DR558 revertida con exito
                  if(data.responseCode === "DR558")
                    this.presentToast("La transacción ha fallado, por favor intenta de nuevo.");
                  else
                    this.presentToast(data.responseMessage);
                  //transaccion no completada con exito, data.responseText -> validar siempre para transaccion valida : 1
                  return;
                
                }    
          },
        error => {
          console.log(error);
          this.loading.dismiss();
          this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
        });

      }
      else
      {

        //VALIDAR SI TIENE MARCADO CHECKBOX PARA GUARDAR TARJETA (TOKENIZAR)
        if(this.chkTokenizar.checked == true)
        {
            //AJUSTE DE FECHA PARA CYBERSOURCE
            console.log("ENTRO A COBRAR CON CYBERSOURCE TOKENIZANDO");
            console.log(this.epayObj);
            this.epayObj.mesExpiracion = this.pago.fechaVencimiento.split("/")[0];
            this.epayObj.anioExpiracion = this.pago.fechaVencimiento.split("/")[1];
            //REALIZAR COBRO Y SI ES EXITOSO TOKENIZAR
            this.epayService.requestCyber(this.epayObj, this.linkObj.token).subscribe(dataCyber =>
            {
          
              //CREAR UN TOKEN PARA LA TARJETA Y REALIZAR COBRO
              console.log("ENTRO A GENERAR TOKEN");
              console.log(dataCyber);
              // this.epayObj.mesExpiracion = this.pago.fechaVencimiento.split("/")[0];
              // this.epayObj.anioExpiracion = this.pago.fechaVencimiento.split("/")[1];

              if(dataCyber.responseCode === "00")
              {

                this.epayService.tokeniza(this.epayObj, this.linkObj.token).subscribe(data =>
                  {
                    console.log("tokenizado");
                    console.log(data);

                    if(this.linkObj.facturaAutomatica == true)
                    {
                      this.facturar(dataCyber);    
                    }   
                    else
                    {
                      this.loading.dismiss();
                      localStorage.setItem("resultado_certificacion_epay", null);
                      localStorage.setItem("epayResponse", JSON.stringify(dataCyber) );
                      this.router.navigate(['/pago-exito-cyber-general' ]);
                    }

                  },
                error => {
                  console.log(error);
                  this.loading.dismiss();
                  this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
                });
            }
            else
            {
              this.loading.dismiss();
              //DR558 revertida con exito
              if(dataCyber.responseCode === "DR558")
                this.presentToast("La transacción ha fallado, por favor intenta de nuevo.");
              else
                this.presentToast(dataCyber.responseMessage);
              //transaccion no completada con exito, data.responseText -> validar siempre para transaccion valida : 1
              return;
            
            }  

            },
            error => {
              console.log(error);
              this.loading.dismiss();
              this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
            });
        }
        else
        {
          //COBRAR CON CYBERSOURCE SIN TOKENIZAR
          console.log("ENTRO A COBRAR CON CYBERSOURCE SIN TOKENIZAR");
          console.log(this.epayObj);
          this.epayObj.mesExpiracion = this.pago.fechaVencimiento.split("/")[0];
          this.epayObj.anioExpiracion = this.pago.fechaVencimiento.split("/")[1];


          this.epayService.requestCyber(this.epayObj, this.linkObj.token).subscribe(data =>
            {
              console.log("COBRO EXITO CYBER");
              console.log(data);

              if(data.responseCode === "00")
              {

                  if(this.linkObj.facturaAutomatica == true)
                  {
                    this.facturar(data);    
                  }   
                  else
                  {
                    this.loading.dismiss();
                    localStorage.setItem("resultado_certificacion_epay", null);
                    localStorage.setItem("epayResponse", JSON.stringify(data) );
                    this.router.navigate(['/pago-exito-cyber-general' ]);
                  }

              }
              else
              {
                this.loading.dismiss();
                //DR558 revertida con exito
                if(data.responseCode === "DR558")
                  this.presentToast("La transacción ha fallado, por favor intenta de nuevo.");
                else
                  this.presentToast(data.responseMessage);
                //transaccion no completada con exito, data.responseText -> validar siempre para transaccion valida : 1
                return;
              
              }  


            },
            error => {
              console.log(error);
              this.loading.dismiss();
              this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
            });

        }
      }

    }
    else
    {
      console.log("ENTRO A CALENDARIZAR RECURRENTE");
      this.epayObj.recurrente = true;
      this.epayObj.diaDeCobro = this.linkObj.diaDeCobro;
      this.epayObj.cantidadPeriodos = this.linkObj.cantidadPeriodos;
      this.epayObj.periodo = this.linkObj.periodo;
      this.epayObj.mesExpiracion = this.pago.fechaVencimiento.split("/")[0];
      this.epayObj.anioExpiracion = this.pago.fechaVencimiento.split("/")[1];

      console.log("COBRO RECURRENTE");
      console.log(this.epayObj);
        //es link recurrente, activar calendarizacion
        this.epayService.requestRecurrencia(this.epayObj, this.linkObj.token).subscribe(data =>
          {
          
              if(data.responseCode === "DR200")
              {

                this.loading.dismiss();
                // localStorage.setItem("resultado_certificacion_epay", null);
                console.log(data);
                this.presentToast(data.responseMessage);
                this.router.navigate(['/recurrencia-exito' ]);
              }
              else
              {
                this.loading.dismiss();
                //DR558 revertida con exito
                if(data.responseCode === "DR558")
                  this.presentToast("La calendarización de cobro ha fallado, por favor intenta de nuevo.");
                else
                  this.presentToast(data.responseMessage);
                //transaccion no completada con exito, data.responseText -> validar siempre para transaccion valida : 1
                return;
              
              }    
          },
        error => {
          console.log(error);
          this.loading.dismiss();
          this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
        });

    }
  



   
   
  }


  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 10000
    });
    toast.present();
  }

  errorImg()
  {
    console.log("sin imagen");
    this.srcimage = "https://digitalrising.com.gt/wp-content/uploads/2020/10/sinperfil.png";  

  }

  facturar(dataTransaccion)
  {
     let doc = new FELObj(this.linkObj.token, this.nit, this.nitnombre,
     this.epayObj.email, this.direccion == "" ? "Ciudad" : this.direccion, "", "", "",
      "", "", "", "", this.linkObj.codigoEstablecimiento.toString(), this.linkObj.establecimiento, "","");

    //    let doc = new FELObj("7d61fe80096fe9a74b9a240689f002ff", this.nit, this.nitnombre,
    //  this.epayObj.email, this.direccion == "" ? "Ciudad" : this.direccion, "", "", "",
    //   "", "", "", "", "1", "DIGITALRISING_DEMO", "","");

    var item = new Item(this.linkObj.nombre,
     this.linkObj.descripcion,
     this.epayObj.amount,
      1,
      0,
      this.linkObj.esServicio == true ? "S" : "B",
      "UN"
    );
    var itemsAux = [];
    itemsAux.push(item);
    doc.agregarItems(itemsAux);
    
    this.felService.facturar(doc).subscribe(data=>{


      let objResultado: resultado_certificacion =  JSON.parse(data.toString());
      this.actualizarTransaccion(doc, objResultado, dataTransaccion);
      localStorage.setItem("resultado_certificacion_epay", JSON.stringify(objResultado));
      localStorage.setItem("epayResponse", JSON.stringify(dataTransaccion) );
      this.loading.dismiss();
      this.router.navigate(['/pago-exito-public' ]);

    },
    error =>
    {
      this.actualizarTransaccion(doc, null, dataTransaccion);
      this.loading.dismiss();
      localStorage.setItem("resultado_certificacion_epay", null);
      localStorage.setItem("epayResponse", JSON.stringify(dataTransaccion) );
      this.router.navigate(['/pago-exito-public' ]);

    }
    );

  }

  actualizarTransaccion(doc: FELObj, objResultado: resultado_certificacion, epayResponse: EpayResponse)
  {
  
    this.epayService.getTransactionAuthorization(epayResponse.authorizationNumber).subscribe(data =>
      {
        console.log(data);
        let transaccionObj: Transaccion = data;
        transaccionObj.factura = objResultado == null ? "" : objResultado.uuid;
        transaccionObj.felJson = JSON.stringify(doc);
        transaccionObj.descripcion = "Descripcion custom";
        if(objResultado != null)
          transaccionObj.resultadoFel = objResultado.resultado == "Exito" ? true : false;
        else
          transaccionObj.resultadoFel = false;
        
    
        console.log(transaccionObj);
        this.epayService.putTransaccion(transaccionObj).subscribe(dataTransac =>
          {
            console.log(dataTransac);
           
          });
       
      });
 
  }
  
  cerrarModal()
  {
    this.modal.dismiss();
  }

}
