import { Carrito } from "./carrito";

export class Pedido {

    constructor(
        public productos: Carrito[],
        public nombre: string,
        public telefono: string,
        public correo: string,
        public direccion: string,
        public colorFondo: string,
        public colorFuente: string,
        public correoComercio: string,
        public idComercio: number,
        public nombreComercio: string,
        public metodoPago: number,
        public cuentaBancaria: string

    ) {}
}
