export class PerfilServicios {

    constructor(
        public planEpay: number,
        public planEcommerce: number,
        public planFel: number,
        public fechaVenceEpay: Date,
        public fechaVenceEcommerce: Date,
        public fechaVenceFel: Date,
        public epayActivo: boolean,
        public ecommerceActivo: boolean,
        public felActivo: boolean,
        public estadoAfiliacionEpay: number,
        public cyberMerchantId?: string,
        public cyberMerchantKeyId?: string,
        public cyberMerchantsecretKey?: string


    ) {}

}
