export class Epay {

    constructor(
        public pan: string,
        public expirationDate: string,
        public amount: number,
        public track2Data: string,
        public cvV2: string,
        public shopperIP: string,
        public additionalData: string,
        public name: string, 
        public email: string,
        public moneda: number,
        public origenTransaccion: number,
        public descripcionTransaccion?: string,
        //nuevos
        public recurrente?: boolean,
        public diaDeCobro?: number,
        public cantidadPeriodos?: number,
        public periodo?: number,
        public mesExpiracion?: string,
        public anioExpiracion?: string,
        public direccion?: string


    ) {}


}
