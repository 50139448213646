import { Component, OnInit, Input } from '@angular/core';
import { FELObj } from '../../models/fel/felobj';
import { resultado_certificacion } from '../../models/fel/resultado_certificacion';
@Component({
  selector: 'app-pop-factura-error',
  templateUrl: './pop-factura-error.component.html',
  styleUrls: ['./pop-factura-error.component.scss'],
})
export class PopFacturaErrorComponent implements OnInit {

  @Input() resultado: resultado_certificacion;

  constructor() { }

  ngOnInit() {
    //console.log("pop factura erronea")
    //console.log(this.resultado);
    //console.log("endpop factura erronea")
  }

}
