import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderbackComponent } from './headerback/headerback.component';
import { MenuComponent } from './menu/menu.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import { HeadermenuComponent } from './headermenu/headermenu.component';
import { PopOpcionesUsuariosComponent } from './pop-opciones-usuarios/pop-opciones-usuarios.component';
import { PopFacturasComponent } from './pop-facturas/pop-facturas.component';
import { PopClienteComponent } from './pop-cliente/pop-cliente.component';
import { PopFacturadorComponent } from './pop-facturador/pop-facturador.component';
import { PopFacturaErrorComponent } from './pop-factura-error/pop-factura-error.component';
import { OpcionesLinkComponent } from './opciones-link/opciones-link.component';
import { PopTransaccionesComponent } from './pop-transacciones/pop-transacciones.component';
import { CarritoComponent } from './carrito/carrito.component';
import { PopPaqueteComponent } from './pop-paquete/pop-paquete.component';
import { NuevoProductoComponent } from './nuevo-producto/nuevo-producto.component';
import { FormsModule } from '@angular/forms';
import { ClientesComponent } from './clientes/clientes.component';
import { PipesModule } from '../pipes/pipes.module';
import { NuevoClienteComponent } from './nuevo-cliente/nuevo-cliente.component';
import { PopOrdenComponent } from './pop-orden/pop-orden.component';
import { PopAnuncioComponent } from './pop-anuncio/pop-anuncio.component';
import { PopOrdenSimpleComponent } from './pop-orden-simple/pop-orden-simple.component';
import { ModalPagoComponent } from './modal-pago/modal-pago.component';
import { BrMaskerModule } from 'br-mask';
import { RptTransaccionesComponent } from './rpt-transacciones/rpt-transacciones.component';
import { ProductoTiendaComponent } from './producto-tienda/producto-tienda.component';
import { EditarProductoTiendaComponent } from './editar-producto-tienda/editar-producto-tienda.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PagoComponent } from './pago/pago.component';
import { AyudaTiendaComponent } from './ayuda-tienda/ayuda-tienda.component';
import { CuentaBancaria } from '../models/cuenta-bancaria';
import { CuentaBancariaComponent } from './cuenta-bancaria/cuenta-bancaria.component';

@NgModule({
  declarations: [ HeaderbackComponent, 
    MenuComponent,
     HeadermenuComponent,
      PopOpcionesUsuariosComponent,
    PopFacturasComponent,
  PopClienteComponent,
PopFacturadorComponent, 
PopFacturaErrorComponent, OpcionesLinkComponent, PopTransaccionesComponent,
CarritoComponent,
PopPaqueteComponent,
NuevoProductoComponent,
ClientesComponent,
NuevoClienteComponent,
PopOrdenComponent,
PopAnuncioComponent,
PopOrdenSimpleComponent,
ModalPagoComponent,
RptTransaccionesComponent,
ProductoTiendaComponent,
EditarProductoTiendaComponent,
CheckoutComponent,
PagoComponent,
AyudaTiendaComponent,
CuentaBancariaComponent

 ],
  exports: [
    HeaderbackComponent,
    MenuComponent,
    HeadermenuComponent,
    PopOpcionesUsuariosComponent,
    PopFacturasComponent,
    PopClienteComponent,
    PopFacturadorComponent,
    PopFacturaErrorComponent,
    OpcionesLinkComponent, 
    PopTransaccionesComponent,
    CarritoComponent,
    PopPaqueteComponent,
    NuevoProductoComponent,
    ClientesComponent,
    NuevoClienteComponent,
    PopOrdenComponent,
    PopAnuncioComponent,
    PopOrdenSimpleComponent,
    ModalPagoComponent,
    RptTransaccionesComponent,
    ProductoTiendaComponent,
    EditarProductoTiendaComponent,
    CheckoutComponent,
    PagoComponent,
    AyudaTiendaComponent,
    CuentaBancariaComponent

  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    PipesModule,
    BrMaskerModule
  ]
})
export class ComponentsModule { }
