import { Component, OnInit } from '@angular/core';
import {  ActionSheetController, ModalController, ToastController } from '@ionic/angular';
import { EpayService } from 'src/app/services/epay.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-rpt-transacciones',
  templateUrl: './rpt-transacciones.component.html',
  styleUrls: ['./rpt-transacciones.component.scss'],
})
export class RptTransaccionesComponent implements OnInit {


  textoBuscarInicio: string = "";
   textoBuscarFin: string = "";
  urlReporte: string = "";
  fechaFiltro = {
    dia: 0,
    mes: 0,
    anio: 0,
    diaF: 0,
    mesF: 0,
    anioF: 0

   };

   fechasCorrectas: boolean = true;
   fechaSet: Date = new Date();


  constructor(private actionSheetController: ActionSheetController,
    private loading: LoadingService,
    private epayService: EpayService,
    private toastCtrl: ToastController,
    public modalCtrl: ModalController) { }

  ngOnInit() {}

  salirModal()
  {
    this.modalCtrl.dismiss();
  }


  ionViewDidEnter()
  {
    this.fechaSet = new Date();
    console.log(this.fechaSet);
    let dia = this.fechaSet.getDate();
    let mes = this.fechaSet.getMonth()+1;
    let anio = this.fechaSet.getFullYear().toString();
    let hora = this.fechaSet.getHours().toLocaleString();
    let minutos = this.fechaSet.getMinutes().toLocaleString();
    let segundos = this.fechaSet.getSeconds().toLocaleString();
    let hora_parse = parseInt(hora) < 10 ? "0" + hora : hora;
    let minutos_parse = parseInt(minutos) < 10 ? "0" + minutos : minutos;
    let segundos_parse = parseInt(segundos) < 10 ? "0" + segundos : segundos;
    console.log(dia);
    console.log(mes);
    console.log(anio);
    console.log(hora);
    console.log(minutos);
    console.log(segundos);
 
    let mesFormat = mes < 10 ? "0" + mes : mes;
    let diaFormat = dia < 10 ? "0" + dia : dia;
    let fechaSetString = anio + "-" + mesFormat + "-" + diaFormat + "T" + hora_parse + ":" + minutos_parse + ":" + segundos_parse + "-06:00";
    console.log(fechaSetString);
    console.log(this.fechaSet.toLocaleTimeString());
     this.textoBuscarInicio = fechaSetString;
     this.textoBuscarFin = fechaSetString;
  }

  buscar_inicio(event: any)
  {
    console.log("FECHA DESDE EVENTO");
    console.log(event.detail.value);
    let fecha_inicio = event.detail.value;
    let fecha_aux: string = fecha_inicio.split("T", 1);

    let filtro = fecha_aux[0].split("-", 3);
    let dia_inicio: number = +filtro[2];
    let mes_inicio: number = +filtro[1];
    let ano_inicio: number = +filtro[0];

    this.fechaFiltro.dia = dia_inicio;
    this.fechaFiltro.mes = mes_inicio;
    this.fechaFiltro.anio = ano_inicio;
    console.log("FILTRO DE FECHA");
    console.log(this.fechaFiltro);
    this.textoBuscarInicio = fecha_inicio;
    this.validarFechas();

  }

  buscar_fin(event: any)
  {
    console.log("FECHA FINAL");
    console.log(event.detail.value);
    let fecha_inicio = event.detail.value;
    let fecha_aux: string = fecha_inicio.split("T", 1);

    let filtro = fecha_aux[0].split("-", 3);
    let dia_inicio: number = +filtro[2];
    let mes_inicio: number = +filtro[1];
    let ano_inicio: number = +filtro[0];



    this.fechaFiltro.diaF = dia_inicio;
    this.fechaFiltro.mesF = mes_inicio;
    this.fechaFiltro.anioF = ano_inicio;
    this.textoBuscarFin = fecha_inicio;
    console.log("FILTRO DE FECHA");
    console.log(this.fechaFiltro);
    
    this.validarFechas();

  }

  validarFechas()
  {
    console.log(this.textoBuscarFin);
    console.log(this.textoBuscarInicio);
    
    if(Date.parse(this.textoBuscarFin) < Date.parse(this.textoBuscarInicio)){
      //start is less than End
      console.log("fecha de fin es menor a inicio NO DEJAR PASAR");
      this.fechasCorrectas = false;
      this.presentToast("Debes seleccionar una fecha de inicio menor o igual a la fecha de fin.");
   }else{
      //end is less than start
      console.log("fecha de fin es mayor a inicio SI DEJAR PASAR");
      this.fechasCorrectas = true;
   }
  }

  
  generarExcel()
  {
    // console.log("llego generar pdf");
    if(this.fechasCorrectas == true)
    {

    
    this.loading.present();
    this.epayService.getRptTransaccionesXLSX(this.fechaFiltro).subscribe(
      {
        next: (data) => 
        {
          this.loading.dismiss();
          this.urlReporte = data.url;
          this.descargar();
        }
      
    });
  }
  else
  {
    this.presentToast("Debes seleccionar una fecha de inicio menor o igual a la fecha de fin.");
  }
        

  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 10000
    });
    toast.present();
  }

  async descargar() {

    const actionSheet = await this.actionSheetController.create({
      header: 'Reporte generado ¿Deseas descargarlo?',
      cssClass: 'nuevosEstados',
      buttons: [{
        text: 'Ver reporte',
        icon: 'document-outline',
        handler: () => {
           //descargar 
           window.open(this.urlReporte);
        }
      }, 
      {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
            console.log("cancelada");
        }
      }]
    });
    await actionSheet.present();
  }

}
