import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Paquete } from '../models/paquete';


@Injectable({
  providedIn: 'root'
})
export class PagosUsuarioService {

  constructor(private http: HttpClient) { }


  getPagos(personaId)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
     return this.http.get(`https://api.swigit.gt/Pago/Usuario/${personaId}`, {headers: headers});
     
  }

  getPaquetes(rol)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
     return this.http.get<Paquete[]>(`https://epayapi.swigit.gt/paquete/${rol}`, {headers: headers});
     
  }

  getVentas(filtroFecha) : Observable<any>
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.post('https://api.swigit.gt/Pago/Ventas', filtroFecha, { headers: headers });
     
  }

  postPago(pagoQpay) : any
  {


    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.post('https://api.swigit.gt/Pago', pagoQpay, { headers: headers });

    
          
  }

}
