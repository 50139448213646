import { DTE } from './dte';

export class SAT {
    public ClaseDocumento: string;
    public DTE: DTE;

    constructor(nit:string, nombre:string, correo:string,
        direccion: string, nit_emisor: string, 
        nombreEmisor:string, codigoEstablecimiento: string, nombreComercial: string, correoEmisor: string, afiliacionIVA: string){
        this.ClaseDocumento = "dte";
        this.DTE = new DTE(nit,nombre,correo,direccion, nit_emisor, nombreEmisor, codigoEstablecimiento, nombreComercial, correoEmisor, afiliacionIVA);
    }
}
