export class resultado_certificacion {
    public resultado: string;
    public fecha: string;
    public descripcion: string;
    public alertas_infile: boolean;
    public alertas_sat: boolean;
    public uuid: string;
    public serie: string;
    public numero: string;
    public motivoError: string;

    constructor(resultado:string,fecha:string, descripcion: string, alertas_infile:boolean,
        alertas_sat:boolean, uuid:string,serie:string, numero:string, motivoError: string){
        this.resultado = resultado;
        this.fecha = fecha;
        this.descripcion = descripcion,
        this.alertas_infile = alertas_infile,
        this.alertas_sat = alertas_sat,
        this.uuid = uuid,
        this.serie = serie,
        this.numero = numero,
        this.motivoError = motivoError
        
    }
}