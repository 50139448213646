import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';


//import { FCM } from '@ionic-native/fcm/ngx';
// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';
import { Router } from '@angular/router';
import { LocalNotifications, ELocalNotificationTriggerUnit } from '@ionic-native/local-notifications/ngx';
import { UsuarioService } from './services/usuario.service';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core';

// const { PushNotifications } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    //private fcm: FCM,
    private localNotifications: LocalNotifications,
    private router: Router,
    private userServ: UsuarioService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
      this.splashScreen.hide();

      /*console.log('La plataforma actual del dispositivo es: ',this.platform.platforms()[0]);
      console.log('Es plataforma Android, ',this.platform.is("android"));
      console.log('Es plataforma mobileweb, ',this.platform.is("mobileweb"));
      console.log('Es plataforma desktop, ',this.platform.is("desktop"))*/
      this.userServ.authenticationState.subscribe(state=>{
      //  console.log('user login changed to: ' + state);
      });
      this.userServ.activeUser.subscribe(state=>{
      //  console.log('user changed to: ' + JSON.stringify(state));
      });
      /*Se verifica en la inicializacion de la app si es nativa o no la plataforma actual */
      if(this.platform.is('desktop') || this.platform.is('mobileweb')){
        localStorage.setItem("isNative", "false");
        localStorage.setItem("screenMovil", this.platform.is('mobileweb').toString());
      }else{
        localStorage.setItem("isNative", "true");
      }

      // this.platform.ready().then(() => {
      //   this.localNotifications.on('click').subscribe(res => { //al momento de hacer tap/click en la notificacion
      //   //  console.log('click: ', res);
      //     let page = res.data ? res.data.myDataPage : '';
      //     let msg = res.data ? res.data.myDataMsg : '';
      //     this.router.navigate([page, msg]);
      //   });
      // });

      console.log('Initializing HomePage');

      if(this.platform.is('desktop') == false || this.platform.is('mobileweb') == false){
  
      // PushNotifications.addListener(
      //   'pushNotificationReceived',
      //   (notification: PushNotification) => {
      

      //   },
      // );
  
      // PushNotifications.addListener(
      //   'pushNotificationActionPerformed',
      //   (notification: PushNotificationActionPerformed) => {
          
      //   },
      //   );

      }
    

      //this.fcm.subscribeToTopic('people');

      /*FCM.getToken().then(token => { 
        console.log('mytoken FCM ' + token);
      }).catch(error=>{
        console.log('error FCM ' + error);
      });*/
      // this.fcm.getToken().then(token => { //para obtener el token
      //   console.log(token);
      // });

      // this.fcm.onTokenRefresh().subscribe(token => { //para refrescar o reobtener el token
      //   console.log(token);
      // });

     /* FCM.onNotification().subscribe(data => {
      //this.fcm.onNotification().subscribe(data => { //las acciones que se realizarán al momento de hacer tap en la notificacion
        //console.log(data);
        if (data.wasTapped) {
          //console.log('Received in background');
          this.router.navigate([data.landing_page, data.dataFromBackend]); //ruta, datos de backend
        } else {
          //console.log('Received in foreground');
          this.localNotifications.schedule({
            id: data.id,
            title: data.title,
            text: data.body,
            smallIcon: '../assets/icon/SwigitIcon.png',
            data: { 
              myDataPage: data.landing_page,
              myDataMsg: data.dataFromBackend},
            trigger: { in: 1, unit: ELocalNotificationTriggerUnit.SECOND}, //notificacion recibida de backend a 1s de ejecutarse
            foreground: true
          });
        }
      });*/

      //this.fcm.unsubscribeFromTopic('people');

    });
  }
}
