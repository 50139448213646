import { Component, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventEmitter } from 'events';
import { Carrito } from 'src/app/models/carrito';
import { LoadingService } from 'src/app/services/loading.service';
import { SharingService } from 'src/app/services/sharing.service';
import { ClientesComponent } from '../clientes/clientes.component';
import { CheckoutComponent } from '../checkout/checkout.component';
import { PerfilPago } from 'src/app/models/perfil-pago';
import { PerfilServicios } from 'src/app/models/perfil-servicios';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit {

 
  arregloCarrito: Carrito[];
  total: number = 0;
  @Output() emiterCarrito = new EventEmitter();
  tipoFlujo: number;
  colorEncabezado: string = "#000";
  perfilpagoObj = new PerfilPago(0,"", "", "", "", "", "", "", "", "", 0,0, "", "");
  perfilServicioObj = new PerfilServicios(0,0,0,null,null,null,false,false,false,0);
  colorLetras: string = "#fff";

  constructor(public modalCtrl: ModalController,
    private sharingService: SharingService,
    public usuarioService: UsuarioService,
    public loadingService: LoadingService) { }

  ngOnInit() {
   
  }

  ionViewDidEnter()
  {
    this.arregloCarrito = this.sharingService.sharingCart;
    this.precargarTotal();
    this.sharingService.sharingCarritoObservable.subscribe((_carrito)=>{
      this.arregloCarrito = _carrito;
    });

    
    if(this.tipoFlujo != 2)
    {
      //setear color de digital
      this.colorEncabezado = "linear-gradient(270deg, #5AC8FA 0%, #01519D 100%), #01519D";
    }
 

    this.getPerfilpago();

  }

  precargarTotal()
  {
    for(let item of this.arregloCarrito)
    {
      this.actualizarTotalInicio(item);
    } 
  }

  eliminar(item)
  {
    this.actualizarTotalEliminar(item);
    let i = this.arregloCarrito.indexOf(item);
    this.arregloCarrito.splice(i,1);
    this.sharingService.setCarritoInfo(this.arregloCarrito);

  }

  sumarCantidad(item)
  {
    item.cantidad = item.cantidad + 1;
    this.actualizarTotal(item, true);
    this.sharingService.setCarritoInfo(this.arregloCarrito);
  }

  restarCantidad(item)
  {
    if(item.cantidad != 1)
    {
      item.cantidad = item.cantidad - 1;
      this.actualizarTotal(item, false);
      this.sharingService.setCarritoInfo(this.arregloCarrito);
    }

  }

  actualizarTotal(item: Carrito, suma: boolean)
  {
    if(suma == true)
      this.total = this.total + item.precio;
    else
      this.total = this.total - item.precio;
  }

  actualizarTotalInicio(item: Carrito)
  {
      this.total = this.total + item.precio*item.cantidad;
  }

  actualizarTotalEliminar(item: Carrito)
  {
      this.total = this.total - item.precio*item.cantidad;
  }

  salirModal()
  {
    this.modalCtrl.dismiss();
  }

  async finalizarCarrito()
  {
    console.log("TIPO FLUJO: " + this.tipoFlujo);
    this.modalCtrl.dismiss();

    if(this.tipoFlujo == 2)
    {
      console.log("carrito final: ", this.arregloCarrito);
      const modal = await this.modalCtrl.create({
        component: CheckoutComponent,
        cssClass: 'my-custom-class'
      });
      await modal.present();
    }
    else
    {

      console.log("carrito final: ", this.arregloCarrito);
      const modal = await this.modalCtrl.create({
        component: ClientesComponent,
        cssClass: 'my-custom-class'
      });
      await modal.present();
    }
  }

  getPerfilpago()
  {
    console.log("llego perfil pago");
    this.usuarioService.getPerfilPagoToken(localStorage.getItem("token")).subscribe(data =>
    {

      this.perfilpagoObj = data;
      console.log("PERFIL PAGO");
      console.log(this.perfilpagoObj);
      
      if(this.perfilpagoObj.colorFondoEncabezadoVoucher != null)
      {
        this.colorEncabezado = this.perfilpagoObj.colorFondoEncabezadoVoucher;
        
      }

      if(this.perfilpagoObj.colorFuenteEncabezadoVoucher != null)
      {
        this.colorLetras = this.perfilpagoObj.colorFuenteEncabezadoVoucher;
      }

      document.documentElement.style.setProperty('--colorEncabezado',this.colorEncabezado.toString());
      document.documentElement.style.setProperty('--colorLetras',this.colorLetras.toString());

    },
    error => {
      console.log(error);
      document.documentElement.style.setProperty('--colorEncabezado',this.colorEncabezado.toString());
      document.documentElement.style.setProperty('--colorLetras',this.colorLetras.toString());

    });
  }



}
