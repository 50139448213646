import { DatosEmision } from './datos-emision';

export class DTE {
    public ID: string;
    public DatosEmision: DatosEmision;

    constructor(nit:string, nombre:string, correo:string,
        direccion: string, nit_emisor: string, 
        nombreEmisor:string, codigoEstablecimiento: string, nombreComercial: string, correoEmisor: string, afiliacionIVA: string){
        this.ID = "DatosCertificados";
        this.DatosEmision = new DatosEmision(nit,nombre,correo,direccion, nit_emisor, nombreEmisor, codigoEstablecimiento, nombreComercial, correoEmisor, afiliacionIVA);
    }
}
