export class CuentaBancaria {


    constructor(
        public nombreBanco: string,
        public tipoCuenta: string,
        public nombreCuenta: string,
        public numeroCuenta: string
    ) {}


}
