import { Component, OnInit, ɵConsole } from '@angular/core';
import { PopoverController, ToastController, AlertController, Platform } from '@ionic/angular';
import { FacturasService } from 'src/app/services/facturas.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
//File
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver/dist/FileSaver';
import { FELObj } from '../../models/fel/felobj';
import { resultado_certificacion } from '../../models/fel/resultado_certificacion';
import { anulacionbj } from '../../models/fel/anulacionobj';
import { FelService } from 'src/app/services/fel.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-pop-facturas',
  templateUrl: './pop-facturas.component.html',
  styleUrls: ['./pop-facturas.component.scss'],
})
export class PopFacturasComponent implements OnInit {

  objeto: any;
  isNative: Boolean;
  constructor(private popCtrl: PopoverController,
              private toastCtrl: ToastController,
              private facturaService: FelService,
              private alertController: AlertController,
              private router: Router,
              private platform: Platform,
              private file: File,
              private ft: FileTransfer,
              private fileOpener: FileOpener,
              private document: DocumentViewer,
              private transfer: FileTransfer, 
              private files: File,
              private http: HttpClient,
              private loading: LoadingService
              ) { }

  fileTransfer: FileTransferObject = this.transfer.create();

  ngOnInit() {
    if(localStorage.getItem("isNative")=="false"){
      this.isNative=false;
    }else{
      this.isNative=true;
    }
    //console.log('isNative : ',this.isNative);
  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 2000
    });
    toast.present();
  }


  verListadoFacturas(factura){

    let objFel: FELObj = JSON.parse(factura.json);

    let navigationExtras: NavigationExtras = {
      state: {
        factura: objFel.GTDocumento.SAT.DTE.DatosEmision.Items //factura.fel.gtDocumento.sat.dte.datosEmision.items
      }
    };
    this.popCtrl.dismiss();
    this.router.navigate(['/detalle-listado-facturas/' + "0"],navigationExtras);
  }

  cambiarEstado(factura)
  {
    //Validar si no esta ya anulada que ya no anule
    let fechaactual: Date = new Date();
    let mesActual = fechaactual.getMonth()+1;
    let diaActual = fechaactual.getDate();
    let mesFactura = Number(factura.fecha[5] + factura.fecha[6]); 
    let dia1 = Number(factura.fecha[8] + factura.fecha[9]);

    if(mesFactura <= 11){
      if((mesFactura < mesActual && mesActual == mesFactura+1 && diaActual <= 5) || (mesFactura == mesActual && diaActual <= 31) ){
        //console.log("si entro");
        this.presentAlertAnular(factura);
      }else{
        //console.log("no entro");
        this.presentToast('Solamente puedes anular las facturas del mes presente o los primeros 5 dias del siguiente. ');
      }
    }else if(mesFactura == 12){
      if((mesActual == mesFactura && diaActual <= 31) || (mesActual == 1 && diaActual <= 5)){
        //console.log("si entro");
        this.presentAlertAnular(factura);
      }else{
        //console.log("no entro");
        this.presentToast('Solamente puedes anular las facturas del mes presente o los primeros 5 dias del siguiente.');
      }
    }
    //this.presentAlertAnular(factura);
  }

  descargarPDF(factura)
  {
    //console.log(factura);
   let downloadUrl = "";
   
   if(localStorage.getItem("token") == "9719707487b6eab34f7fb62255c46a17" || localStorage.getItem("token") == "ada7f02880481b181c6335fe3fcbeee1"
    || localStorage.getItem("token") == "7d61fe80096fe9a74b9a240689f002ff"  || localStorage.getItem("token") == "3e000ae5d76680b09ed2b27326266c87"
    || localStorage.getItem("token") == "afcc7aaee2173b21a7f947677cc9e976" || localStorage.getItem("token") == "c6cfc4b66aff2013ba0118e187f538e1"
    || localStorage.getItem("token") == "14bbf8fadafbbe72097fe44e5cd2ef2d" || localStorage.getItem("token") == "a52cd454962362e6d53f6e81a7b18ae9" )
      downloadUrl = "https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=" + factura.uid;
   else
      downloadUrl = 'https://files.swigit.gt/' + localStorage.getItem("nit_emisor") + '/' + factura.uid + '.pdf';
   
   //Ruta de ejemplo:https://www.swigit.gt/100888704/845F046E-88CE-44C7-9489-B97A0CEA4449.pdf
   //anterior: 'https://www.digitalrising.com.gt/wp-content/uploads/2020/09/dte_2020-09-10.pdf';
   //let downloadUrl = 'https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + factura.uid;
   //debe ser un .pdf let downloadUrl = 'http://www.swigit.gt/100888704/09092020-luis_lopez-CF-20FD02BE-FCC7-461C-A527-6D2884E78224.pdf';
 
   let path = this.file.dataDirectory;
    const transfer = this.ft.create(); //download the file

    if(this.isNative == false || this.platform.is('mobileweb')==true){
      //console.log('web browser');
      //console.log(downloadUrl);
      window.open(downloadUrl);
    } else {
      //console.log("entro movil");
      //console.log(downloadUrl);
      transfer.download(downloadUrl, `${path}` + factura.uid + '.pdf')
        .then(entry => {
          let url = entry.toURL();
          if(this.platform.is('ios')){
            //console.log('iOS device');
            this.document.viewDocument(url,'application/pdf', {});
          } else if(this.platform.is('android')){
            //console.log('Android device');
            this.fileOpener.open(url, 'application/pdf');
          } 
        });
    }
  }
  

  async presentAlertAnular(factura) {    

    let nombre_emisor = localStorage.getItem("nombre_emisor");
    let nombre_comercial= localStorage.getItem("nombre_comercial");
    let afiliacionIVA = localStorage.getItem("afiliacionIVA");
    let usuario_infile = localStorage.getItem("usuario_infile");
    let alias = localStorage.getItem("alias");
    let llave_certificacion = localStorage.getItem("llave_certificacion");
    let llave_firma = localStorage.getItem("llave_firma");
    let codigo_establecimiento = localStorage.getItem("codigo_establecimiento");
    let nit_emisor = localStorage.getItem("nit_emisor");
    let correo_emisor = localStorage.getItem("email"); 
    let clientKey = localStorage.getItem("token");

    //console.log(JSON.stringify(factura))
    //console.log(factura)
    let doc = new anulacionbj(usuario_infile, llave_certificacion, llave_firma, 
                              alias, correo_emisor, nit_emisor, factura.fecha,
                              factura.nit, "anulada", factura.uid,clientKey
                              );

    //console.log("doc:" + JSON.stringify(doc))

    const alert = await this.alertController.create({
      header: 'Anular factura',
      message: ' <p style="font-style: normal; font-weight: bold; font-size: 17.904px; line-height: 24px;color: #4A4D4E">¿Estas seguro de anular la factura de ' + factura.nombre + ' ?</p>',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //no desea anular
            this.popCtrl.dismiss();
          }
        }, {
          text: 'Anular',
          handler: () => {
              //si desea anular factura
              this.loading.present();
         
              this.facturaService.anular(doc).subscribe(data=>{

                //console.log("RESPUESTA");
                //console.log(data);
                let objResultado: resultado_certificacion = JSON.parse(data.toString());
                //console.log(objResultado.resultado);
                //console.log(objResultado.uuid);

              

                if(objResultado.resultado == "Exito")
                {
                  factura.estado = 2;
                  this.loading.dismiss();
                  this.presentToast('Anulaste la factura');
                  // this.presentToast('Por favor espera mientras se anula la factura... ');
                  // setTimeout(() => {
                  //   this.loading.dismiss();
                  //   this.presentToast('Anulaste la factura');
                  // },15000);
                }
                else
                {
                  
                  this.loading.dismiss();
                  this.presentToast(objResultado.motivoError);
                }
                
              });
  
              this.popCtrl.dismiss();
      
             
          }
        }
      ]
    });

    await alert.present();
  }



}
