import { Component, Input, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/services/cliente.service';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Clientes } from 'src/app/models/clientes.model';
import { Producto } from 'src/app/models/producto';
import { ProductoService } from 'src/app/services/producto.service';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';

@Component({
  selector: 'app-nuevo-producto',
  templateUrl: './nuevo-producto.component.html',
  styleUrls: ['./nuevo-producto.component.scss'],
})
export class NuevoProductoComponent implements OnInit {

  nuevoProducto = new Producto(0,"","","",0,"",0,false);
  personaIdCreada: any;
  @Input() productoEdit: Producto;
  activarCodigoB: boolean = false;
  public options: BarcodeScannerOptions;
  esServicio: string;
  formData: FormData = new FormData();
  
    constructor(private clienteService: ClienteService,
                private alertController: AlertController,
                private toastCtrl: ToastController,
                private router: Router,
                public modalCtrl: ModalController,
                private productoService: ProductoService,
                private barcodeScanner: BarcodeScanner) { }
  
    ngOnInit() {

      this.options = {
        preferFrontCamera: false, // iOS and Android
        showFlipCameraButton: true, // iOS and Android
        showTorchButton: true, // iOS and Android
        prompt: "Escanea el código de barras o QR", // Android
        disableSuccessBeep: false // iOS and Android
      }
   
    }

    ionViewDidEnter()
    {
      console.log("producto edit: ", this.productoEdit);
      if(this.productoEdit != null)
      {
        this.nuevoProducto = this.productoEdit;
        console.log("nuevoProducto: ", this.nuevoProducto);
      }
    }

    async presentAlertConfirm() {
      const alert = await this.alertController.create({
        header: 'Cliente creado',
        message: ' <p style="font-style: normal; font-weight: bold; font-size: 17.904px; line-height: 24px;color: #4A4D4E">¿Deseas generar una factura?</p>',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              //no desea crear factura
              this.router.navigate(['/cliente']);
            }
          }, {
            text: 'Aceptar',
            handler: () => {
                //si desea crear factura
                this.router.navigate(['/facturador/' + this.personaIdCreada]);
            }
          }
        ]
      });
  
      await alert.present();
    }
  
  
    guardar(){

      if(this.productoEdit != null)
      {
        console.log("editar");
      }
      else
      {
        this.nuevoProducto.esServicio = this.esServicio == "B" ? false : true;
        console.log("crear: " , this.nuevoProducto);
      }

      /*
      this.productoService.postProducto(this.nuevoProducto).subscribe(data =>
      {
        console.log("creado");

      },
      error => {
        console.log(error);
        this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
      });

      */

      this.mandarImagen(1);

    }

    
    async presentToast(mensaje: string) {
      const toast = await this.toastCtrl.create({
        message: mensaje,
        duration: 2000
      });
      toast.present();
    }

    salirModal()
    {
      this.modalCtrl.dismiss();
    }

    escanear()
    {

  
        this.barcodeScanner.scan(this.options).then(data => {
          console.log('Scanner Data: ' + data.text + ' Format: ' + data.format );
          this.nuevoProducto.codigoBarras = data.text;
        }).catch(err => {
          console.log("Error: ", err);
        });
  
    }

    handleFileInput(files: FileList) {
      // console.log(files.item(0));
      this.formData = new FormData();
      this.formData.append('fileKey', files.item(0), files.item(0).name);

    }


    mandarImagen(id)
    {
      // console.log(pagoId);
      var request = new XMLHttpRequest();
      request.open("POST", "https://api.swigit.gt/Producto/Upload/"+ id);
      request.send(this.formData);
    }
  

}
