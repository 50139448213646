import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, map, tap, delay, switchMap } from 'rxjs/operators';
import { Clientes } from '../models/clientes.model';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {


  baseUrl = 'https://api.swigit.gt/';
  constructor(private http: HttpClient) { }

  private _clientes = new BehaviorSubject<Clientes[]>([]);


  getProductos(idusuario)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
     return this.http.get(`${this.baseUrl}Producto/Usuario/${idusuario}`, { headers: headers });
     
  }

  getProducto(idProducto)
  {
    //console.log(idcliente);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.get(`${this.baseUrl}Producto/${idProducto}`, { headers: headers });
     
  }

  postProducto(producto)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    return this.http.post('https://api.swigit.gt/Producto/CrearProducto', producto, { headers: headers });
    
  }

  putProducto(producto)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    this.http.put('https://api.swigit.gt/Producto/ModificarProducto', producto, { headers: headers }).subscribe();
    
  }

  deleteProducto(producto)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'token': localStorage.getItem("token")
      });
    this.http.post('https://api.swigit.gt/Producto/EliminarProducto', producto, { headers: headers }).subscribe();


  }
}
