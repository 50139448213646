import { Component, OnInit, ViewChild } from '@angular/core';
import {Observable} from 'rxjs';
import { IonSegment, IonList, ToastController, PopoverController, IonSearchbar, NavController, AlertController, ModalController } from '@ionic/angular';
import { ClienteService } from 'src/app/services/cliente.service';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Router } from '@angular/router';
import { PopClienteComponent } from 'src/app/components/pop-cliente/pop-cliente.component';
import { UsuarioService } from 'src/app/services/usuario.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Carrito } from 'src/app/models/carrito';
import { SharingService } from 'src/app/services/sharing.service';
import { resultado_certificacion } from 'src/app/models/fel/resultado_certificacion';
import { Item } from 'src/app/models/fel/item';
import { FelService } from 'src/app/services/fel.service';
import { FELObj } from 'src/app/models/fel/felobj';
import { PopFacturadorComponent } from '../pop-facturador/pop-facturador.component';
import { PopFacturaErrorComponent } from '../pop-factura-error/pop-factura-error.component';
import { NuevoClienteComponent } from '../nuevo-cliente/nuevo-cliente.component';
import { CarritoComponent } from '../carrito/carrito.component';
import { WordPressService } from 'src/app/services/word-press.service';
import { PopOrdenComponent } from '../pop-orden/pop-orden.component';
import { PopOrdenSimpleComponent } from '../pop-orden-simple/pop-orden-simple.component';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss'],
})
export class ClientesComponent implements OnInit {

  clientes: Observable<any>;
  @ViewChild(IonSegment, null) segment: IonSegment;
  estado: string;
  textoBuscar: string;
  @ViewChild('listaClientes', null) listaClientes: IonList;
  public toggled: boolean = false;
  token: string;
 //validacion saldo
 usuario: any;
 personaId: string;
 arregloCarrito: Carrito[];
 total: number = 0;
 clienteSeleccionado: any;
 url: string;
 consumer_key: string;
 consumer_secret: string;
 activarFacturacion:boolean = true;
 ordenCompletada:boolean = true;

  constructor(private clienteService: ClienteService, 
              private toastCtrl: ToastController,
              private callNumber: CallNumber,
              private router: Router,
              private popCtrl: PopoverController,
              private navctrl: NavController,
              private usuarioService: UsuarioService,
              private alertController: AlertController,
              private loading: LoadingService,
              private modalCtrl: ModalController,
              private sharingService: SharingService,
              private felService: FelService,
              public wordPressService: WordPressService) { }

  ngOnInit() {
   //inicie
    this.loading.present();
    this.toggled = false;
    // this.segment.value = 'Todos';
    this.estado = '';
    this.textoBuscar = '';
    this.token = localStorage.getItem("token");
    this.clientes = this.clienteService.getClientes(localStorage.getItem("personaId"));
   //finalice
    this.loading.dismiss();
   
  }


  ionViewDidEnter() {
  
    this.token = localStorage.getItem("token");
    this.clientes = this.clienteService.getClientes(localStorage.getItem("personaId"));
    console.log("clientes ionviewdidenter: ", this.clientes);

    this.personaId = localStorage.getItem("personaId");
    this.usuario = this.usuarioService.getUsuario(this.personaId).subscribe(data =>
      {
        this.usuario = data;
        this.url = this.usuario.urlPOSWC; //"agdesign.com.gt";
        this.consumer_key = this.usuario.consumerKey; //"ck_2c1391e03f828ab74df3d68ff04e169dfbb32d98";
        this.consumer_secret = this.usuario.consumerSecret; //"cs_585012f85ef10df5077c3ca7b8a68dc18a63c3e3";
  
      },
      error => {
        //console.log(error);
        this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
      });

      this.arregloCarrito = this.sharingService.sharingCart;
      this.precargarTotal();
      this.sharingService.sharingCarritoObservable.subscribe((_carrito)=>{
        this.arregloCarrito = _carrito;
      });

   
  }



  precargarTotal()
  {
    for(let item of this.arregloCarrito)
    {
      this.actualizarTotalInicio(item);
    } 
  }

  actualizarTotalInicio(item: Carrito)
  {
      this.total = this.total + item.precio*item.cantidad;
  }


  async handleButtonClick() {
    this.listaClientes.closeSlidingItems();
    const alert = await this.alertController.create({
      header: 'Paquete Expirado',
      message: 'Para utilizar todas las caracteristicas de swigit debes tener un paquete activo. ¿Deseas adquirir un paquete?',
      buttons: [
          {
            text:'Cancelar',
            handler: () => {
              this.router.navigate(['/resumen-facturacion']);
            }
          }, {
            text:'Adquirir',
            handler: () => {
              //this.router.navigate(['/pago']);
              this.navctrl.navigateRoot('/paso2-paquetes');
            }
          }],
      backdropDismiss: false
    });

    await alert.present();
  }

  public toggle(): void {
    this.toggled = !this.toggled;
 }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 2000
    });
    toast.present();
  }



  buscar(event)
  {
    this.textoBuscar = event.detail.value;
    
  }

  
  async salirModal()
  {
    this.modalCtrl.dismiss();
    console.log("carrito final: ", this.arregloCarrito);
    const modal = await this.modalCtrl.create({
      component: CarritoComponent,
      cssClass: 'my-custom-class'
    });
    await modal.present();
    
  }

  toggleSeleccionado(cliente)
  {
    if(cliente == this.clienteSeleccionado)
    {
      this.clienteSeleccionado = null;
      console.log("clienteSeleccionado: ", this.clienteSeleccionado);
    }
    else
    {
      this.clienteSeleccionado = cliente;
      console.log("clienteSeleccionado: ", this.clienteSeleccionado);
    }
  }

  crearOrdenWC()
  {
    this.loading.present();
    var itemsLine = [];
    for(let xproducto of this.arregloCarrito)
    {
      var item = 
      {
        product_id: xproducto.id,
        quantity: xproducto.cantidad
      }
      itemsLine.push(item);
    }

    let jsonOrden = {
      payment_method: "Tienda",
      status: this.ordenCompletada == true ? "completed" : "processing",
      billing:
        {
          first_name: this.clienteSeleccionado == null ? "Invitado" : this.clienteSeleccionado.cliente.nombre1,
          email: this.clienteSeleccionado == null ? localStorage.getItem("email") : this.clienteSeleccionado.cliente.email,
          address_1: "Ciudad"
        },
      line_items: itemsLine,
      meta_data:
          [{
              key:"billing_nit",
              value: this.clienteSeleccionado == null ? "CF" : this.clienteSeleccionado.cliente.nit,
            },
            {
              key:"billing_nitnombre",
              value: this.clienteSeleccionado == null ? "Consumidor Final" : this.clienteSeleccionado.cliente.razonSocial,
            },
            {
              key:"nit_client",
              value: this.clienteSeleccionado == null ? "CF" : this.clienteSeleccionado.cliente.nit,
            },
            {
              key:"error-fel",
              value: "",
            },
            {
              key:"is_vat_exempt",
              value: "no",
            },
            {
              key:"meta-fel",
              value: "0",
            },
          ]
        
           
      };

      console.log(jsonOrden);

    this.wordPressService.postOrdenWC(this.url, this.consumer_key, this.consumer_secret, jsonOrden).subscribe(data =>
      {
        console.log(data);
        let ordenCreada: any = data;
        if(this.activarFacturacion == true)
          this.facturar(ordenCreada);
        else
        {
          this.loading.dismiss();
          this.modalCtrl.dismiss();
          this.mostrarPopOrdenSimple(ordenCreada.id.toString());
        }
      }
    );
  }



  facturar(ordenCreada)
  {

    console.log("llego facturar: ", this.clienteSeleccionado);
      let doc = new FELObj(localStorage.getItem("token"), this.clienteSeleccionado == null ? "CF" : this.clienteSeleccionado.cliente.nit,
      this.clienteSeleccionado == null ? "Consumidor Final" : this.clienteSeleccionado.cliente.razonSocial,
      this.clienteSeleccionado == null ? localStorage.getItem("email") : this.clienteSeleccionado.cliente.email,
      this.clienteSeleccionado == null ? "Ciudad" : this.clienteSeleccionado.cliente.direccion,
        "", "", "",
      "", "", "", "", this.sharingService.sharingValue.split("-")[0], this.sharingService.sharingValue.split("-")[1], "","");
 
    var itemsAux = [];
    console.log(this.arregloCarrito);
    for(let xproducto of this.arregloCarrito)
    {
      var item = new Item(xproducto.nombre,
      "",xproducto.precio, xproducto.cantidad,0,xproducto.esServicio == true ? "S" : "B","UN");      
      itemsAux.push(item);
      
    }

    doc.agregarItems(itemsAux);
    


    this.felService.facturar(doc).subscribe(data=>{

      this.loading.dismiss();
      this.modalCtrl.dismiss();
      //actualizar meta datos de pedido
      this.actualizarPedido(ordenCreada, data);


      this.mostrarPopOrden(data, ordenCreada.id.toString());
      
    },
    error =>
    {
    }
    );

  }


  actualizarPedido(ordenCreada, factura)
  {

    let objResultado: resultado_certificacion = JSON.parse(factura); //detalle; 

    let arregloMeta = {
      meta_data:
        [{
            key:"meta-fel",
            value:"1",
          },
          {
            key:"uuid",
            value:objResultado.uuid,
          }
        ]
      };

      console.log("ORDEN CREADA: ");
      console.log(ordenCreada);
      console.log(arregloMeta);

      this.wordPressService.putOrdenWC(this.url, this.consumer_key
         ,this.consumer_secret, arregloMeta, ordenCreada.id);
   
  }

  limpiarCarrito()
  {
    console.log("llego limpiar carrito");
    this.arregloCarrito = [];
    console.log("carrito limpio: ", this.arregloCarrito);
    this.sharingService.setCarritoInfo(this.arregloCarrito);
  }

  async mostrarPop(detalle) {

    let objResultado: resultado_certificacion = JSON.parse(detalle);

    if(objResultado.resultado == "Exito")
    {
      this.limpiarCarrito();
        const popover = await this.popCtrl.create({
          component: PopFacturadorComponent,
          componentProps: { resultado : objResultado},
          event: detalle
        });
        return await popover.present();
    }
    else
    {
      const popover = await this.popCtrl.create({
        component: PopFacturaErrorComponent,
        componentProps: { resultado : objResultado},
        event: detalle
      });
      return await popover.present();

    }
  }

  
  async mostrarPopOrden(detalle, ordenId) {

    let objResultado: resultado_certificacion = JSON.parse(detalle); //detalle; 


    if(objResultado.resultado == "Exito")
    {
      this.limpiarCarrito();
        const popover = await this.popCtrl.create({
          component: PopOrdenComponent,
          componentProps: { resultado : objResultado,
                          ordenId: ordenId
                        },
          event: detalle
        });
        return await popover.present();
    }
    else
    {
      const popover = await this.popCtrl.create({
        component: PopFacturaErrorComponent,
        componentProps: { resultado : objResultado},
        event: detalle
      });
      return await popover.present();

    }
  }

  async mostrarPopOrdenSimple(ordenId) {

    this.limpiarCarrito();
    const popover = await this.popCtrl.create({
      component: PopOrdenSimpleComponent,
      componentProps: { 
                      ordenId: ordenId
                    },
      event: ordenId
    });
    return await popover.present();
  }

  async nuevoCliente()
  {
    this.modalCtrl.dismiss();
    console.log("carrito final: ", this.arregloCarrito);
    const modal = await this.modalCtrl.create({
      component: NuevoClienteComponent,
      cssClass: 'my-custom-class'
    });
    await modal.present();
  }




}
