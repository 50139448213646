export class AuthorizationRequestWithClientToken {


    constructor(
        public tokenCliente: string,
        public moneda: number,
        public amount: number,
        public debtCollectorIP: string,
        public descripcionTransaccion?: string
    
    ) {}


}
