export class Link {

    constructor(
        public linkPagoToken: string,
        public nombre: string,
        public descripcion: string,
        public precio: number,
        public moneda: number,
        public cuotas: string,
        public token: string,
        public razonSocial: string,
        public nit: string,
        public email: string,
        public establecimiento: string,
        public codigoEstablecimiento: number,
        public facturaAutomatica: boolean,
        public esServicio: boolean,
        //nuevos
        public recurrente?: boolean,
        public diaDeCobro?: number,
        public cantidadPeriodos?: number,
        public periodo?: number,
        public esCyber?: boolean,
        public lp?: number,
        public esFichaProducto?: boolean
    ) {}
}
