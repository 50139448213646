import { MontoImpuesto } from './monto-impuesto';

export class Impuesto {
    public NombreCorto: string;
    public CodigoUnidadGravable: string;
    public MontoGravable: number;
    public MontoGravableSpecified: boolean;

    public MontoImpuesto: MontoImpuesto;

    constructor(montogravable:number, impuesto:number){
        this.MontoGravable = montogravable;
        this.NombreCorto = "IVA";
        this.CodigoUnidadGravable = "1";
        this.MontoGravableSpecified = true;
        this.MontoImpuesto = new MontoImpuesto(impuesto);
    }

}
