import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Carrito } from '../models/carrito';
import { Producto } from '../models/producto';
import { Pedido } from '../models/pedido';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  private sharingObject: any;
  private sharingCarrito: Carrito[];
  private sharingPedidoObject: Pedido;
  sharingCarritoObservable = new BehaviorSubject([]);
  private sharingWCProductos: Producto[];
  sharingProductosObservable = new BehaviorSubject([]);

  constructor() { }

  get sharingValue() {
    return this.sharingObject
  }

  set sharingValue(obj) {
    this.sharingObject = obj;
  }

  get sharingCart() {
    return this.sharingCarrito
  }

  set sharingCart(obj) {
    this.sharingCarrito = obj;
  }


  get sharingWCProducto() {
    return this.sharingCarrito
  }

  set sharingWCProducto(obj) {
    this.sharingCarrito = obj;
  }

  get sharingPedido() {
    return this.sharingPedidoObject
  }

  set sharingPedido(obj) {
    this.sharingPedidoObject = obj;
  }

  setCarritoInfo(carritoobj){
 
      this.sharingCarritoObservable.next(carritoobj);

  }

  setProductoInfo(productoObj){
 
    this.sharingProductosObservable.next(productoObj);

}

}
