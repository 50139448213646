import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Link } from '../../models/link';
import { EpayService } from 'src/app/services/epay.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuarios } from 'src/app/models/usuarios.model';
import { Establecimientos } from 'src/app/models/Establecimientos';
import { PerfilServicios } from 'src/app/models/perfil-servicios';
import { Pedido } from 'src/app/models/pedido';
import { Carrito } from 'src/app/models/carrito';
import { SharingService } from 'src/app/services/sharing.service';
import { PagoComponent } from '../pago/pago.component';
import { PerfilPago } from 'src/app/models/perfil-pago';
import { CuentaBancaria } from 'src/app/models/cuenta-bancaria';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {

  linkObj = new Link("","","",0,1,"", "","", "", "", "", 0,false, false,false, 0,0,0,false,0,true);
  pedidoObj = new Pedido(null, "","","","", "", "", "", 0, "", 0, "");
  arregloCarrito: Carrito[];
  moneda = "1";
  cuotasSeleccionadas = ["0-00"];

  
  linkIdCifrado = "12234dsdasdsad";
  baseUrl = "https://swigit.gt/link/";
  baseUrlFicha = "https://swigit.gt/link-producto/";

  //integracion FEL
  establecimiento: string;
  listaEstablecimientos: Establecimientos[];
  facturacionAutomatica: boolean = false;
  // recurrencia: boolean = false;
  cobroCuotas: boolean = false;
  felActivo: boolean = false;
  bienoServicio: string = "S";
  planEpay: number;
  //integracion FEL

  //cybersource
  cyberMerchantID: string = null;

  //ficha de producto
  esFichaProducto: boolean = false;
  formData: FormData = new FormData();

  //checkout
  metodoPago: number = 1;
  textoPago: string = "Realizar pago";
  colorEncabezado: string = "#000";
  colorLetras: string = "#fff";
  total: number = 0;
  perfilpagoObj = new PerfilPago(0,"", "", "", "", "", "", "", "", "", 0,0, "", "");
  perfilServicioObj = new PerfilServicios(0,0,0,null,null,null,false,false,false,0);
  usuario: any;
  @ViewChild('contentPrincipal', null) private contentPrincipal: any;
  cuentaBancaria = new CuentaBancaria("","","", "");

    constructor(private epayService: EpayService,
                private alertController: AlertController,
                private toastCtrl: ToastController,
                private router: Router,
                private loading: LoadingService,
                private usuarioService: UsuarioService,
                private sharingService: SharingService,
                public modalCtrl: ModalController) { }
  
    ngOnInit() {
    }

    ionViewDidEnter()
    {
     
      this.metodoPago = 1;
      this.arregloCarrito = this.sharingService.sharingCart;
      this.sharingService.sharingCarritoObservable.subscribe((_carrito)=>{
        this.arregloCarrito = _carrito;
      });
   
      this.getServicios();
      this.getPerfilpago();
      this.precargarTotal();
      this.obtenerUsuario();
    }

    obtenerUsuario()
    {
      this.usuario = this.usuarioService.getUsuarioToken(localStorage.getItem("token")).subscribe(data =>
       {
        this.usuario = data;
        if(this.usuario.persona.cuentaBancaria == null)
        {
          this.cuentaBancaria = null;
        }
        else
        {
          this.cuentaBancaria = JSON.parse(this.usuario.persona.cuentaBancaria);
          
        }
        console.log("RESPONDIO GET USUARIO POR TOKEN");
        console.log(this.usuario);
      },
      error => {
        console.log(error);
        this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
      });
    }

    salirModal()
    {
      this.modalCtrl.dismiss();
    }

    precargarTotal()
    {
      for(let item of this.arregloCarrito)
      {
        this.actualizarTotalInicio(item);
      } 
    }

    actualizarTotalInicio(item: Carrito)
    {
        this.total = this.total + item.precio*item.cantidad;
    }

    getServicios()
    {
  
      this.usuarioService.getPerfilServiciosPublic(localStorage.getItem("token")).subscribe(data =>
        {
          console.log("PERFIL DE SERVICIOS");
          console.log(data);
          this.perfilServicioObj = data;
          this.felActivo =  this.perfilServicioObj.felActivo;
          this.planEpay =  this.perfilServicioObj.planEpay;
          document.documentElement.style.setProperty('--colorEncabezado',this.colorEncabezado.toString());
          document.documentElement.style.setProperty('--colorLetras',this.colorLetras.toString());
    
          if(this.perfilServicioObj.epayActivo == false) //su plan no incluye pasarela
          {
            this.metodoPago = 2;
            this.textoPago = "Realizar pedido";
    
          }
  
        },
        error =>
        {
          this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
        }
      );
    }

    getPerfilpago()
    {
      console.log("llego perfil pago");
      this.usuarioService.getPerfilPagoToken(localStorage.getItem("token")).subscribe(data =>
      {
  
        this.perfilpagoObj = data;
        console.log(this.perfilpagoObj);
        
        if(this.perfilpagoObj.colorFondoEncabezadoVoucher != null)
        {
          this.colorEncabezado = this.perfilpagoObj.colorFondoEncabezadoVoucher;
          
        }
  
        if(this.perfilpagoObj.colorFuenteEncabezadoVoucher != null)
        {
          this.colorLetras = this.perfilpagoObj.colorFuenteEncabezadoVoucher;
        }
  
        document.documentElement.style.setProperty('--colorEncabezado',this.colorEncabezado.toString());
        document.documentElement.style.setProperty('--colorLetras',this.colorLetras.toString());
  
      },
      error => {
        console.log(error);
        document.documentElement.style.setProperty('--colorEncabezado',this.colorEncabezado.toString());
        document.documentElement.style.setProperty('--colorLetras',this.colorLetras.toString());
  
      });
    }
  


    cambioMetodo(evento)
    {
      console.log(evento.detail.value);
      this.metodoPago = evento.detail.value;
      if(this.metodoPago == 1)
      {
        this.textoPago = "Realizar pago";
      }
      else
      {
        this.textoPago = "Realizar pedido";
      }

      this.contentPrincipal.scrollToBottom(400);
    }
  
  
    async realizarPedido(){
 
     // this.loading.present();

      this.pedidoObj.productos = this.arregloCarrito;
      this.pedidoObj.colorFondo = this.colorEncabezado;
      this.pedidoObj.colorFuente = this.colorLetras;
      this.pedidoObj.correoComercio = this.usuario.persona.email; 
      this.pedidoObj.idComercio = this.usuario.persona.personaId; 
      this.pedidoObj.nombreComercio = this.usuario.persona.razonSocial; 
      this.pedidoObj.telefono =  this.pedidoObj.telefono.toString(); 
      console.log(this.pedidoObj);

      if(this.cuentaBancaria != null)
      {
        this.pedidoObj.cuentaBancaria = JSON.stringify(this.cuentaBancaria);
      }

      if(this.metodoPago == 1) //tarjeta
      {
          this.pedidoObj.metodoPago = 1;
          this.sharingService.sharingPedido = this.pedidoObj;
          this.modalCtrl.dismiss();
          const modal = await this.modalCtrl.create({
            component: PagoComponent,
            cssClass: 'my-custom-class'
          });
          await modal.present();
      }
      else //transferencia > crear pedido
      {
        this.pedidoObj.metodoPago = 2;
        console.log(this.pedidoObj);
        this.loading.present();
        this.epayService.postPedido(this.pedidoObj).subscribe(data =>
          {
           console.log("DATA PEDIDO");
           console.log(data);
           this.loading.dismiss();
           this.modalCtrl.dismiss();
           if(data.codigoRespuesta == "DR200")
           { 
              this.limpiarCarrito();
              console.log("PEDIDO CREADO EXITOSAMENTE");
              console.log(data);
              this.router.navigate(['/exito-pedido/' + data.pedido ]);
           }
           else
           {
            this.presentToast("Ha ocurrido un error, por favor intenta de nuevo.");
           }

          },
          error => {
            console.log(error);
            this.loading.dismiss();
            this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
          }); 
        
      }
      /*
       //fin pruebas
       this.epayService.postLink(this.linkObj).subscribe(data =>
       {
        console.log("DATA LINK");
        console.log(data);
          this.loading.dismiss();
       },
       error => {
         console.log(error);
         this.loading.dismiss();
         this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
       });
       */


    }
    async presentToast(mensaje: string) {
      const toast = await this.toastCtrl.create({
        message: mensaje,
        duration: 6000
      });
      toast.present();
    }

    copyMessage(val: string){
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

    limpiarForm()
    {
      this.linkObj = new Link("","","",0,1,"", "","", "", "", "",0,false, false);
    }

    limpiarCarrito()
    {
      console.log("llego limpiar carrito");
      this.arregloCarrito = [];
      console.log("carrito limpio: ", this.arregloCarrito);
      this.sharingService.setCarritoInfo(this.arregloCarrito);
    }



}
