import { TotalImpuestos } from './total-impuestos';

export class Totales {
    public TotalImpuestos = [];
    public GranTotal: number;

    constructor(total: number, nombre: string, totalimpuesto:number){
        this.GranTotal = total;
        var n = new TotalImpuestos(nombre, totalimpuesto);
        this.TotalImpuestos.push({NombreCorto: nombre, TotalMontoImpuesto: totalimpuesto});
    }
}
