import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-ayuda-tienda',
  templateUrl: './ayuda-tienda.component.html',
  styleUrls: ['./ayuda-tienda.component.scss'],
})
export class AyudaTiendaComponent implements OnInit {

  constructor( public modalCtrl: ModalController) { }

  ngOnInit() {}

  
  salirModal()
  {
    this.modalCtrl.dismiss();
  }

}
