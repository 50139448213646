import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { InicioPage } from 'src/app/pages/inicio/inicio.page';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Observable} from 'rxjs';
import { PerfilServicios } from 'src/app/models/perfil-servicios';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  personaId: string;
  usuario: string;
  empresa: string;
  nit: string;
  anuncioActivo: boolean = false;

  user={user:'',empresa:'',tipo:0,img:''};
  usertype=0;
  perfilServicioObj: PerfilServicios;

  constructor(private router: Router,
    public menuCtrl: MenuController,
    private authService:UsuarioService,
    private platform: Platform,
    private usuarioService: UsuarioService) { }

  ngOnInit() {

    
    this.personaId = localStorage.getItem("personaId");
    this.usuario = localStorage.getItem("usuario");
    this.empresa = localStorage.getItem("empresa");
    this.nit = localStorage.getItem("nit_emisor");
    this.authService.refresh();
    this.authService.activeUser.subscribe((_user)=>{
      if(_user)
        this.user = _user
      else
        this.user={user:'',empresa:'',tipo:0,img:''};
      
    });

    this.validarAnuncio();
  

  }

  validarAnuncio()
  {
    let fechaHoy: Date = new Date();
    let mesActual: number = fechaHoy.getMonth() + 1;
    if(mesActual == 2)
    {
      this.anuncioActivo = true;
    }
    else
    {
      this.anuncioActivo = false;
    }

    console.log("ANUNCIO ACTIVO: " + this.anuncioActivo);

  }

  ionWillOpen()
  {
    console.log("llego menu ionDidOpen");
    this.perfilServicioObj = JSON.parse(localStorage.getItem("perfilServicios"));
    console.log(this.perfilServicioObj);
  }



  
  logOut()
  {
    //console.log(localStorage.getItem("usuario"));
    delete localStorage["personaId"];
    delete localStorage["usuario"];
    delete localStorage["usuarioId"];
    delete localStorage["email"];
    delete localStorage["empresa"];
    delete localStorage["rol"];
    delete localStorage["objetoUsuario"];

    //campos de perfil tributario
    delete localStorage["nombre_emisor"];
    delete localStorage["nombre_comercial"];
    delete localStorage["afiliacionIVA"];
    delete localStorage["usuario_infile"];
    delete localStorage["alias"];
    delete localStorage["llave_certificacion"];
    delete localStorage["llave_firma"];
    delete localStorage["codigo_establecimiento"];

    //campos perfil servicio
    delete localStorage["perfilServicios"];
  
   
    /*
    localStorage.deleteItem("personaId", this.usuario.persona.personaId);
    localStorage.deleteItem("usuarioId", this.usuario.persona.personaId);
    localStorage.deleteItem("usuario", this.usuario.persona.nombre1);
    localStorage.deleteItem("email", this.usuario.persona.email);
    localStorage.deleteItem("empresa", "Digital");
    */ 
    this.authService.userLogout();
    this.user={user:'',empresa:'',tipo:0,img:'' };
    this.menuCtrl.toggle();
    this.router.navigate(['/inicio']);
  }

  abrirFacturador(){
    localStorage.setItem("clienteId", "-1");
    this.router.navigate(['/facturador/' + "0"]);
  }

  ayuda()
  {
    if(!this.platform.is('ios') && !this.platform.is('android')){
      //console.log('web browser');

    } 
    else {
      //console.log('movil');
    }

  }

  errorImg()
  {
    //console.log("sin imagen");
    this.user.img = "https://digitalrising.com.gt/wp-content/uploads/2020/10/sinperfil.png";  

  }
}
