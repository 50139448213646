import { Component, OnInit, Output } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Link } from '../../models/link';
import { EpayService } from 'src/app/services/epay.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PerfilServicios } from 'src/app/models/perfil-servicios';
import { Establecimientos } from 'src/app/models/Establecimientos';
import { UsuarioService } from 'src/app/services/usuario.service';


@Component({
  selector: 'app-editar-producto-tienda',
  templateUrl: './editar-producto-tienda.component.html',
  styleUrls: ['./editar-producto-tienda.component.scss'],
})
export class EditarProductoTiendaComponent implements OnInit {

  
  linkIdCifrado = "12234dsdasdsad";
  baseUrl = "https://swigit.gt/link/";
  baseUrlFicha = "https://swigit.gt/link-producto/";
  linkId:string;
  link: any;
  moneda = "1";
  linkObj = new Link("","", "", 
  0, 1, "", "", "", "", "", "", 0, false, false);
  monedaString: string;
  cuotas = [];

  cuotaSeleccionada: string;
  //integracion FEL
  planEpay: number;
  establecimiento: string;
  listaEstablecimientos: Establecimientos[];
  facturacionAutomatica: boolean = false;
  felActivo: boolean = false;
  bienoServicio: string = "S";
  //integracion FEL

  //Recurrencia
  cobroCuotas: boolean = false;
  cyberMerchantID: string = null;
  diaCobro: string;
  cantidadMeses: string;
  periodo: string;
  
  //ficha producto
  formData: FormData = new FormData();
  itemProducto: any;
  
    constructor(private epayService: EpayService,
                private alertController: AlertController,
                private toastCtrl: ToastController,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private loading: LoadingService,
                private usuarioService: UsuarioService,
                public modalCtrl: ModalController) { }
  
    ngOnInit() {

      this.loading.present();
      this.linkIdCifrado = this.itemProducto.linkPagoToken; // this.activatedRoute.snapshot.paramMap.get("linkCifrado");
      this.epayService.getLink(this.linkIdCifrado).subscribe(data =>
       {
   
         this.linkObj = data;
         this.moneda = this.linkObj.moneda.toString();
         this.cuotas = this.linkObj.cuotas.split(",");
         this.loading.dismiss();
       },
        error => {
          //console.log(error);
          this.loading.dismiss();
          this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
        });


    }

    ionViewDidEnter(){

      console.log("LLEGO EDICION DE PRODUCTO");
      console.log(this.itemProducto);
      this.linkIdCifrado = this.itemProducto.linkPagoToken;
      this.epayService.getLink(this.linkIdCifrado).subscribe(data =>
       {

         this.linkObj = data;
         this.moneda = this.linkObj.moneda.toString();
         this.cuotas = this.linkObj.cuotas.split(",");
         this.setEstablecimientos();
         this.getServicios();

          if(this.linkObj.facturaAutomatica == true)
          {
            this.facturacionAutomatica = true;
            this.bienoServicio = this.linkObj.esServicio == true ? "S" : "B";
            
            this.establecimiento = this.linkObj.codigoEstablecimiento.toString() + "-" + this.linkObj.establecimiento;
          }

          if(this.linkObj.recurrente == true)
          {
            //PRUEBA CYBERSOURCE
            // this.linkObj.recurrente = true;
            this.diaCobro = this.linkObj.diaDeCobro.toString();
            this.periodo = this.linkObj.periodo.toString();
          }


     
      
       },
        error => {
          //console.log(error);
          this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
        });



    }

    handleFileInput(files: FileList) {
      // console.log(files.item(0));
      this.formData = new FormData();
      this.formData.append('fileKey', files.item(0), files.item(0).name);
  
    }

    mandarImagen(Id)
    {
      // console.log(pagoId);
      var request = new XMLHttpRequest();
      request.open("POST", "https://fileapi.swigit.gt/Upload/"+ Id);
      request.onload =  () => {
        if (request.readyState === 4) {
          if (request.status === 200) {
            this.loading.dismiss();
            this.presentToast("Producto editado");
            this.salirModal();
       
          } else {
            this.loading.dismiss();
          }
        }
      };
      request.onerror = () =>  {
        this.loading.dismiss();
      };
      request.send(this.formData);
    }


    getServicios()
    {
  
      let perfilServicioObj: PerfilServicios = JSON.parse(localStorage.getItem("perfilServicios"));
      this.felActivo = perfilServicioObj.felActivo;
      this.planEpay = perfilServicioObj.planEpay;
      this.cyberMerchantID = perfilServicioObj.cyberMerchantId;
    }
  

    async presentAlertConfirm() {
      const alert = await this.alertController.create({
        header: 'Link editado',
        message: ' <p style="font-style: normal; font-weight: bold; font-size: 17.904px; line-height: 24px;color: #4A4D4E">¡Listo! Puedes copiar o ver tu link de pago </p>',
        buttons: [
          {
            text: 'Copiar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
 
             // this.copyMessage(this.baseUrl + this.linkIdCifrado);
              this.presentToast('¡Excelente! Puedes compartir el link a tu cliente. ');
            }
          }, {
            text: 'Ver ',
            handler: () => {

              if(this.linkObj.esFichaProducto == false)
                this.router.navigate(['/link/' + this.linkIdCifrado]);
              else
                this.router.navigate(['/link-producto/' + this.linkIdCifrado]);
            }
          }
        ]
      });
  
      await alert.present();
    }
  
  
    editarLink(){

      var cuotasList = "";
      //se arma el objeto los campos externos
      this.linkObj.moneda = parseInt(this.moneda);
      this.cuotas.forEach(function (value) {
         console.log(value);
         if(cuotasList != "") 
           cuotasList = cuotasList + "," + value;
         else
           cuotasList = value;

       }); 
     // var request = new XMLDocument;
      this.linkObj.cuotas = cuotasList;
     // var document = new XMLDocument();
      if(this.facturacionAutomatica == true)
      {
      this.linkObj.establecimiento = this.establecimiento.split("-")[1];
      this.linkObj.codigoEstablecimiento = parseInt(this.establecimiento.split("-")[0]);
      this.linkObj.facturaAutomatica = this.facturacionAutomatica;
      this.linkObj.esServicio = this.bienoServicio == "S" ? true : false;
      }
      else
      {
        this.linkObj.facturaAutomatica = false;
        this.linkObj.codigoEstablecimiento = 0;
        this.linkObj.esServicio = false;
        this.linkObj.establecimiento = "";
      }

      console.log(this.linkObj);
      if(this.linkObj.recurrente == true)
      {
        this.linkObj.diaDeCobro = parseInt(this.diaCobro);
        this.linkObj.periodo = parseInt(this.periodo);
      }

      console.log("formData");
      console.log(this.formData.has("fileKey"));

       //fin pruebas
       this.epayService.putLink(this.linkObj).subscribe(data =>
       {

        if(this.linkObj.esFichaProducto == true && this.formData.has("fileKey"))
        {
          this.mandarImagen(data.lp);
        }
        else
        {
          this.loading.dismiss();
          this.presentToast("Producto editado");
          this.salirModal();
        }

          /*
        if(this.linkObj.esFichaProducto == false)
          this.copyMessage(this.baseUrl + this.linkIdCifrado);
        else
          this.copyMessage(this.baseUrlFicha + this.linkIdCifrado);


         this.presentAlertConfirm();
         */

       },
       error => {
         console.log(error);
         this.presentToast('Ocurrio un error, intenta de nuevo más tarde y si persiste por favor dirígete a nuestra area de ayuda. ');
       });


    }
    async presentToast(mensaje: string) {
      const toast = await this.toastCtrl.create({
        message: mensaje,
        duration: 2000
      });
      toast.present();
    }

    salirModal()
    {
      this.modalCtrl.dismiss();
    }

    copyMessage(val: string){
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

    setEstablecimientos()
    {
      this.usuarioService.getUsuarioId(localStorage.getItem("personaId")).subscribe(data =>
      {
        console.log(data);
        this.listaEstablecimientos = data.listaEstablecimientos;
        this.linkObj.facturaAutomatica == false
          this.establecimiento = this.listaEstablecimientos[0].codigoEstablecimiento + "-" + this.listaEstablecimientos[0].nombreComercial;
      });
    }


}
