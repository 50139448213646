export class NombreNitRespuesta {


    constructor(
        public codigoRespuesta: string,
        public tipoMensaje: number,
        public mensajeRespuesta: string,
        public nombre: string

    ) {}


}
