import { Direccion } from './direccion';

export class Receptor {
    public IDReceptor: string;
    public NombreReceptor: string;
    public CorreoReceptor: string;

    public DireccionReceptor: Direccion;

    constructor(nit:string, nombre:string, correo:string,
                direccion: string){
        this.IDReceptor = nit;
        this.NombreReceptor = nombre;
        this.CorreoReceptor = correo;

        this.DireccionReceptor = new Direccion(direccion);
    }
}
