export class Producto {


    constructor(
        public id: number,
        public sku: string,
        public urlImagen: string,
        public nombre: string,
        public precio: number,
        public descripcion: string,
        public inventario: number,
        public esServicio: boolean,
        public seleccionado?: boolean,
        public codigoBarras?: string,
        public variable?: boolean


    ) {}


}
